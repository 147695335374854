<template>
  <MasterDetail
    data-test-id="contractServicePage"
    :detail-open="!!selectedContract"
    :loading="runningAction"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        class="contract-table-toolbar"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
        }"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-spacer />
            <v-switch
              v-model="groupByContractKey"
              data-test-id="groupByContractKeySwitch"
              label="Group by contract key"
              color="primary"
              hide-details
              class="mt-0 ml-3"
              :disabled="runningAction"
            />
            <v-divider vertical class="mx-4" />
            <v-btn
              color="primary"
              class="ml-3"
              data-test-id="newCustomerBtn"
              title="Create a new customer"
              :fab="showMinified"
              :small="showMinified"
              :disabled="runningAction || isModeCreate"
              @click="createContract()"
            >
              <v-icon v-if="showMinified">mdi-plus</v-icon>
              <div v-else>New Contract</div>
            </v-btn>
          </div>
        </template>
      </Toolbar>
    </template>

    <template #table="{ loading }">
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        fixed-header
        single-select
        multi-sort
        class="contracts-table"
        data-test-id="contractsTable"
        :items-per-page="-1"
        :items="contracts"
        :headers="headers"
        :loading="loading"
        :height="tableHeight"
        :item-class="getItemClass"
        :options.sync="options"
        :group-by="groupBy"
        @click:row="openContractDetail"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:group.header="{ group, isOpen, toggle }">
          <td :colspan="headers.length" :data-test-id="'group_header_' + group">
            <div class="d-flex align-center pa-1">
              <v-icon
                @click="toggle"
                :data-test-id="'group_' + group + '_toogle_btn'"
              >
                {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
              <div class="ml-3 font-weight-medium text-truncate">
                {{ group }}
              </div>
            </div>
          </td>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.validFrom="{ item }">
          {{
            item.validFrom
              ? $getLocalizedDate(item.validFrom, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : "-"
          }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.validUntil="{ item }">
          {{
            item.validUntil
              ? $getLocalizedDate(item.validUntil, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              : "-"
          }}
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <ContractDetail
        v-if="selectedContract"
        :preview-data="selectedContract"
        :key="selectedContract.id + selectedContract.termsVersion"
        :previous-versions="previousVersions[selectedContract.id]"
        :contracts="contracts"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeContractDetail"
        @reload="loadContracts"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "components/common/templates/MasterDetail.vue";
import Toolbar from "components/common/templates/Toolbar";
import ContractDetail from "./ContractDetail";
import mainOverviewMixin from "mixins/main-overview-mixin";
import sortQueryMixin from "mixins/sort-query-mixin.js";
import contractServiceMixin from "mixins/contract-service-mixin";
export default {
  mixins: [mainOverviewMixin, sortQueryMixin, contractServiceMixin],

  components: {
    MasterDetail,
    Toolbar,
    ContractDetail,
  },

  data() {
    return {
      contracts: [],
      runningAction: false,
      selectedContract: null,
      filterQuery: null,
      filters: [],
      options: {
        sortBy: ["type", "locale"],
        sortDesc: [false, false],
      },
      previousVersions: {},
      tableWidth: null,
      groupByContractKey: true,
      groupBy: ["type"],
    };
  },

  created() {
    this.init();
  },

  watch: {
    filterQuery: {
      handler: async function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        const queryFilter = query.filter;
        //remove filter query if the query is faulty
        if (Object.keys(filterQuery).length > 0) {
          let copy = this.$cloneObject(filterQuery);
          if (this.$isPlainObject(copy)) {
            copy = JSON.stringify(copy);
          }
          query.filter = this.$urlEncode(copy);
        } else {
          delete query.filter;
        }
        await this.$router.push({ ...this.$route, query });
        if (queryFilter !== query.filter) this.loadContracts();
      },
      deep: true,
    },

    async options(options, old) {
      if (
        this.$isEqual(options.sortBy, old.sortBy) &&
        this.$isEqual(options.sortDesc, old.sortDesc)
      ) {
        //If sortBy and sortDesc did not change, do not update the query
        return;
      }
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);
      await this.$router.push({ ...this.$route, query });
    },

    groupByContractKey(groupByContractKey) {
      if (groupByContractKey) {
        this.groupBy = ["type"];
      } else {
        this.groupBy = [];
      }
    },
  },

  methods: {
    async init() {
      //initialize the component with the route parameters
      let namedRoute = this.$route.name;
      let routeQuery = this.$route.query;

      //get the query parameters and update the according variables
      let filter = routeQuery.filter;
      let sort = routeQuery.sort;

      if (filter) {
        try {
          filter = this.$urlDecode(filter);
          this.filterQuery = JSON.parse(filter);
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      if (sort) {
        const options = this.parseSortQueryToOptions(sort);
        this.options = Object.assign(this.options, options);
      }

      let query = {};
      if (this.filterQuery)
        query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
      if (this.options.sortBy?.length > 0 && this.options.sortDesc?.length > 0)
        query.sort = sort ?? this.parseOptionsToSortQuery(this.options);

      //update the URL query
      await this.$router.push({ ...this.$route, query });

      if (
        namedRoute === "contracts" ||
        (namedRoute === "contractDetail" && !this.selectedContract)
      ) {
        //load users either if the table page has changed or the user is requested directly via URL
        //if a filter is set as query parameter, do not load it here, because the customers are already reloaded when changing the filter
        await this.loadContracts();
      }

      if (namedRoute === "contractDetail") {
        //show user detail of requested customer
        const contractType = this.$route.params.contract;
        const locale = this.$route.params.locale;
        const id = this.getContractId({ contractType, locale });

        if (!contractType) {
          this.closeContractDetail();
        } else if (id !== this.selectedContract?.id) {
          let contract = this.contracts.find((contract) => id === contract.id);
          if (!contract) {
            const contracts = await this.getContractsByLocales();
            contract = contracts.find((contract) => contract.id === id);
            if (!contract) {
              this.$store.dispatch(
                "setError",
                "Cannot find contract with key " +
                  contractType +
                  " and locale " +
                  locale
              );
              this.closeContractDetail();
              return;
            }
          }

          this.openContractDetail(contract);
        }
      } else {
        this.selectedContract = null;
      }
    },

    async loadContracts(contractId) {
      try {
        this.runningAction = true;
        const locales = Object.values(this.filters ?? {}).reduce(
          (array, filter) => {
            if (filter.property === "locale") {
              array.push(filter.value);
            }
            return array;
          },
          []
        );

        const contracts = await this.getContractsByLocales(locales);
        this.previousVersions = {};
        this.contracts = contracts.reduce((contractsArray, contract) => {
          const id = this.getContractId(contract);
          const type = contract.contractType.toUpperCase();
          let existing = contractsArray.find((item) => item.id === id);
          if (!existing) {
            contractsArray.push({
              ...contract,
              type,
              id,
            });
            this.$set(this.previousVersions, id, []);
          } else {
            let previousVersions = this.previousVersions[id];
            if (existing.termsVersion > contract.termsVersion) {
              previousVersions.push(contract);
            } else {
              previousVersions.push(existing);
              existing = {
                ...contract,
                type,
                id,
              };
            }
            //Sort versions descending
            previousVersions.sort(
              (v1, v2) => v2.termsVersion - v1.termsVersion
            );
            this.$set(this.previousVersions, id, previousVersions);
          }
          return contractsArray;
        }, []);
        if (contractId) {
          const contract = this.contracts.find(({ id }) => id === contractId);
          this.$set(this.$route.params, "unsavedChanges", false);
          if (!contract) {
            this.closeContractDetail();
            return;
          }
          this.openContractDetail(contract);
        }
      } finally {
        this.runningAction = false;
      }
    },

    async getContractsByLocales(locales = []) {
      //Get all contracts and filter by locales on the frontend
      //so that the UI has all information
      const res = await this.$store.$coreApi.coreContractApi.getContractTerms(
        this.selectedDomain,
        { locales },
        {
          suppressedErrorCodes: [400, 404],
        }
      );
      if (!res?.ok) return [];
      const response = await res.json();
      const contracts = response?.contracts ?? [];
      return contracts;
    },

    getItemClass(item) {
      const classes = ["contract-" + item.contractKey];
      if (item.id === this.selectedContract?.id) {
        classes.push("selected");
      }
      return classes.join(" ");
    },

    async openContractDetail(contract) {
      await this.$router.push({
        name: "contractDetail",
        params: {
          contract: contract.contractType,
          locale: contract.locale,
        },
        query: this.$route.query,
      });
      this.selectedContract = contract;
    },

    async closeContractDetail() {
      await this.$router.push({
        name: "contracts",
        query: this.$route.query,
      });
      this.selectedContract = null;
    },

    async createContract() {
      await this.closeContractDetail();
      this.selectedContract = {};
    },
  },

  computed: {
    headers() {
      return [
        { text: "Type", value: "type" },
        { text: "Locale", value: "locale" },
        { text: "Valid from", value: "validFrom" },
        { text: "Valid until", value: "validUntil" },
        { text: "Latest version", value: "termsVersion" },
      ];
    },

    possibleFilters() {
      return [
        {
          text: "Locale",
          property: "locale",
          type: "text",
        },
      ];
    },

    showMinified() {
      return (
        (this.selectedContract && this.tableWidth <= 900) ||
        this.getPageWidth() <= 900
      );
    },

    isModeCreate() {
      return !!this.selectedContract && this.selectedContract?.id === undefined;
    },
  },
};
</script>

<style scoped>
.contract-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}
</style>