var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{attrs:{"data-test-id":"contractServicePage","detail-open":!!_vm.selectedContract,"loading":_vm.runningAction},on:{"table:resize":function($event){_vm.tableWidth = $event}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('Toolbar',{staticClass:"contract-table-toolbar",attrs:{"search-props":{
        disableFullTextSearch: true,
        possibleFilters: _vm.possibleFilters,
      }},on:{"update-filters":function (updated) { return (_vm.filters = updated); }},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"extended-toolbar"},[_c('v-spacer'),_c('v-switch',{staticClass:"mt-0 ml-3",attrs:{"data-test-id":"groupByContractKeySwitch","label":"Group by contract key","color":"primary","hide-details":"","disabled":_vm.runningAction},model:{value:(_vm.groupByContractKey),callback:function ($$v) {_vm.groupByContractKey=$$v},expression:"groupByContractKey"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","data-test-id":"newCustomerBtn","title":"Create a new customer","fab":_vm.showMinified,"small":_vm.showMinified,"disabled":_vm.runningAction || _vm.isModeCreate},on:{"click":function($event){return _vm.createContract()}}},[(_vm.showMinified)?_c('v-icon',[_vm._v("mdi-plus")]):_c('div',[_vm._v("New Contract")])],1)],1)]},proxy:true}]),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})]},proxy:true},{key:"table",fn:function(ref){
      var loading = ref.loading;
return [_c('v-data-table',{staticClass:"contracts-table",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","multi-sort":"","data-test-id":"contractsTable","items-per-page":-1,"items":_vm.contracts,"headers":_vm.headers,"loading":loading,"height":_vm.tableHeight,"item-class":_vm.getItemClass,"options":_vm.options,"group-by":_vm.groupBy},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openContractDetail},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
      var group = ref.group;
      var isOpen = ref.isOpen;
      var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":_vm.headers.length,"data-test-id":'group_header_' + group}},[_c('div',{staticClass:"d-flex align-center pa-1"},[_c('v-icon',{attrs:{"data-test-id":'group_' + group + '_toogle_btn'},on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-down" : "mdi-chevron-right")+" ")]),_c('div',{staticClass:"ml-3 font-weight-medium text-truncate"},[_vm._v(" "+_vm._s(group)+" ")])],1)])]}},{key:"item.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.validFrom ? _vm.$getLocalizedDate(item.validFrom, { year: "numeric", month: "numeric", day: "numeric", }) : "-")+" ")]}},{key:"item.validUntil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.validUntil ? _vm.$getLocalizedDate(item.validUntil, { year: "numeric", month: "numeric", day: "numeric", }) : "-")+" ")]}}],null,true)})]}},{key:"detail",fn:function(){return [(_vm.selectedContract)?_c('ContractDetail',{key:_vm.selectedContract.id + _vm.selectedContract.termsVersion,style:({
        height: _vm.detailHeight + 'px',
      }),attrs:{"preview-data":_vm.selectedContract,"previous-versions":_vm.previousVersions[_vm.selectedContract.id],"contracts":_vm.contracts},on:{"close":_vm.closeContractDetail,"reload":_vm.loadContracts}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }